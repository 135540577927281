import React from 'react'
import { Pressable, StyleSheet, PressableProps } from 'react-native'
import Typography from 'components/Typography'

interface MainButtonType {
  text: string
  onClick: (data: unknown) => void
  disabled?: boolean
}

const styles = StyleSheet.create({
  button: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 30,
    elevation: 3,
    borderWidth: 2,
    borderColor: '#FD8898',
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#FD8898'
  },
  buttonOnHover: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 30,
    elevation: 3,
    borderWidth: 2,
    borderColor: '#fecad1',
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: '#fecad1'
  },
  buttonOnPress: {
    alignItems: 'center',
    justifyContent: 'center',
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 30,
    elevation: 3,
    borderWidth: 2,
    borderColor: '#FD8898',
    marginTop: 20,
    marginBottom: 20,
    backgroundColor: 'white'
  }
})

interface CustomStyleProps extends PressableProps {
  pressed: boolean
  hovered: boolean
  focused: boolean
}

function MainButton ({ text, onClick, disabled }: MainButtonType): JSX.Element {
  return (
    <Pressable
      accessibilityRole="button"
      style={({ pressed, hovered, focused }:CustomStyleProps) => (pressed ? styles.buttonOnPress : (hovered || focused || disabled ? styles.buttonOnHover : styles.button))}
      onPress={onClick}
      disabled={disabled}
    >
      {({ pressed }) => <Typography type="button" pointerEvents='none' color={pressed ? '#FD8898' : 'black'}>{text}</Typography>}
    </Pressable>
  )
}

export default MainButton
