import { useEnv, useUser } from './hooks'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import * as Linking from 'expo-linking'
import { StatusBar } from 'expo-status-bar'
import { RootStackParamList } from 'hooks/Route'
import LogRocket from 'logrocket'
import React, { useEffect, useMemo, useState } from 'react'
import { Provider, DefaultTheme } from 'react-native-paper'
import { ExitButton } from 'components'
import {
  Login,
  Profile,
  Success,
  LegalRep,
  TaxData,
  LegalDocs,
  Members,
  Complete
} from 'screens'

const theme = {
  ...DefaultTheme,
  roundness: 10,
  colors: {
    ...DefaultTheme.colors,
    primary: '#FD8898',
    accent: '#FD8898'
  }
}

const { Navigator, Screen } = createNativeStackNavigator<RootStackParamList>()

const App = () => {
  const [email, setEmail] = useState(null)

  useEffect(() => {
    Linking.getInitialURL().then((url) => {
      const {
        queryParams: { email: queryEmail }
      } = Linking.parse(url)
      setEmail(queryEmail)
      // DEVELOPMENT && queryEmail && alert(queryEmail)
    })
  }, [])

  const { user, actions: userActions } = useUser()
  const { env } = useEnv()

  useEffect(() => {
    if (user.id !== 0) {
      if (!env.isDevice) {
        LogRocket.init('jdosza/aroundapp')
        LogRocket.identify(String(user.id), {
          name: user.name,
          email: user.email
        })
      }
    }
  }, [user, env])

  useMemo(() => {
    // initil login using email as password ⏲
    email
      ? userActions.login({ user: email, pass: email })
      : userActions.updateUser({ ...user, loading: false })
  }, [email])

  return (
    <Provider theme={theme}>
      <StatusBar style="dark" />
      <NavigationContainer>
        <Navigator
          screenOptions={() => ({
            headerShown: true,
            headerTitle: 'Around',
            headerTitleStyle: { fontSize: 40 },
            headerRight: () =>
              user.id != 0 &&
              <ExitButton
                text="Salir"
                onClick={() => {
                  userActions.logut()
                }}
            />
          })}
        >
          <Screen name="Login">
            {(props) => <Login {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="Success">
            {(props) => <Success {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="Profile">
            {(props) => <Profile {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="TaxData">
            {(props) => <TaxData {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="LegalRep">
            {(props) => <LegalRep {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="LegalDocs">
            {(props) => <LegalDocs {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="Members">
            {(props) => <Members {...props} {...{ user, userActions }} />}
          </Screen>
          <Screen name="Complete">
            {(props) => <Complete {...props} {...{ user, userActions }} />}
          </Screen>
        </Navigator>
      </NavigationContainer>
    </Provider>
  )
}

export default App
