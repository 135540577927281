import { ErrorMessage } from '@hookform/error-message'
import { AroundDetails, FileUpload, MainButton, Typography } from 'components'
import { useEnv } from 'hooks'
import React, { memo, useMemo } from 'react'
import { useForm, Controller, ValidationMode } from 'react-hook-form'

import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  View
} from 'react-native'
import { RouterProps } from 'hooks/Route'
import { Files } from 'hooks/User'
import { UserType } from 'hooks/types'

type ProfileData = {
 oficialId: {
  name: string
  value: string
 }
 proofOfAddress: {
  name: string
  value: string
 }
 constitutiveAct: {
  name: string
  value: string
 }
 proofOfTaxStatus: {
  name: string
  value: string
 }
}

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const LegalDocs = ({
  navigation,
  user,
  userActions: { uploadDocs }
}: {
 user: UserType
 userActions: {
  updateInfo: (data: ProfileData) => void
  uploadDocs: (files: Files) => void
 }
} & RouterProps) => {
  const formConfig: {
  mode: keyof ValidationMode
  defaultValues: ProfileData
 } = useMemo(() => {
   return {
     mode: 'onChange',
     defaultValues: {
       oficialId: {
         name:
      user.rshContactFiles.find(({ document_type: dt }) => dt === 'oficial_id')
        ?.name_file || 'Identificación Oficial',
         value: null
       },
       proofOfAddress: {
         name:
      user.rshContactFiles.find(
        ({ document_type: dt }) => dt === 'proof_of_address'
      )?.name_file || 'Comprobante de domicilio',
         value: null
       },
       constitutiveAct: {
         name:
      user.rshContactFiles.find(
        ({ document_type: dt }) => dt === 'constitutive_act'
      )?.name_file ||
      (user.rshTaxData.person_type === 'fisic' &&
       'Constancia de inscripción al SAT') ||
      'Acta Constitutiva',
         value: null
       },
       proofOfTaxStatus: {
         name:
      user.rshContactFiles.find(
        ({ document_type: dt }) => dt === 'proof_of_tax_status'
      )?.name_file || 'Constancia de situación fiscal',
         value: null
       }
     }
   }
 }, [user])

  // const filed:boolean = useMemo(() => Object.values(formConfig.defaultValues).every(({ name }) => name), [user])

  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm<ProfileData>(formConfig)
  const {
    env: { isPortrait, isDevice }
  } = useEnv()

  return (
  <TouchableWithoutFeedback onPress={() => isDevice && Keyboard.dismiss()}>
   <ScrollView
    style={{ backgroundColor: 'white', opacity: user.loading ? 0.5 : 1 }}
    contentContainerStyle={{ flexGrow: 1 }}
   >
    <View
     style={
      isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape
     }
    >
     {!isPortrait && (
      <View style={styles.leftContainerLandscape}>
       <Typography type="title">Tu around</Typography>
       <AroundDetails user={user} />
      </View>
     )}
     <View
      style={
       isPortrait
         ? styles.rightContainerPortrait
         : styles.rightContainerLandscape
      }
     >
      <Typography type="subTitle">Perfil de tu empresa</Typography>
      <Typography type="title">Documentos legales</Typography>
      <Typography type="description">
       Necesitamos estos documentos para afinar los detalles de tu contrato
      </Typography>

      <Controller
       control={control}
       rules={{
         required: true,
         validate: ({ value }) => value !== null || 'Este campo es obligatorio'
       }}
       render={({
         field: {
           value: { name },
           onChange
         }
       }) => <FileUpload title={name} onPickFile={onChange} />}
       name="oficialId"
      />
      <ErrorMessage
       errors={errors}
       name="oficialId"
       render={({ message }) => <Typography type="error">{message}</Typography>}
      />

      <Controller
       control={control}
       rules={{
         required: true,
         validate: ({ value }) => value !== null || 'Este campo es obligatorio'
       }}
       render={({
         field: {
           value: { name },
           onChange
         }
       }) => <FileUpload title={name} onPickFile={onChange} />}
       name="proofOfAddress"
      />
      <ErrorMessage
       errors={errors}
       name="proofOfAddress"
       render={({ message }) => <Typography type="error">{message}</Typography>}
      />

      <Controller
       control={control}
       rules={{
         required: true,
         validate: ({ value }) => value !== null || 'Este campo es obligatorio'
       }}
       render={({
         field: {
           value: { name },
           onChange
         }
       }) => <FileUpload title={name} onPickFile={onChange} />}
       name="constitutiveAct"
      />
      <ErrorMessage
       errors={errors}
       name="constitutiveAct"
       render={({ message }) => <Typography type="error">{message}</Typography>}
      />
      <Controller
       control={control}
       rules={{
         required: true,
         validate: ({ value }) => value !== null || 'Este campo es obligatorio'
       }}
       render={({
         field: {
           value: { name },
           onChange
         }
       }) => <FileUpload title={name} onPickFile={onChange} />}
       name="proofOfTaxStatus"
      />
      <ErrorMessage
       errors={errors}
       name="proofOfTaxStatus"
       render={({ message }) => <Typography type="error">{message}</Typography>}
      />

      <Typography type="terms">
       Tus documentos serán correctamente resguardados y utilizados únicamente
       para validar la existencia de tu empresa y no tener que pedirte un
       depósito.
      </Typography>

      <MainButton
       disabled={!(/* filed || */ isValid)}
       text="Crear cuenta"
       onClick={handleSubmit(data => {
         const files = Object.entries(data).map(([key, { name, value }]) => ({
           key,
           name,
           value
         }))
         uploadDocs(files)
       })}
      />
     </View>
    </View>
   </ScrollView>
  </TouchableWithoutFeedback>
  )
}

export default memo(LegalDocs)
