import React from 'react'
import AppLoading from 'expo-app-loading'
import { StyleSheet, View } from 'react-native'
import { useFonts } from 'expo-font'
import { createIconSetFromIcoMoon } from '@expo/vector-icons'

const IconCustom = createIconSetFromIcoMoon(
  require('../assets/fonts/icomoon/selection.json'),
  'IcoMoon',
  'icomoon.ttf'
)

interface IconTest {
  nameIcon: string
  colorIcon: string
  sizeIcon: number,
  bgColor: string
}

const styles = StyleSheet.create({
  containerIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100
  }
})

function Icon ({ nameIcon, colorIcon, sizeIcon, bgColor }: IconTest): JSX.Element {
  const [fontsLoaded] = useFonts({ IcoMoon: require('../assets/fonts/icomoon/icomoon.ttf') })
  if (!fontsLoaded) {
    return <AppLoading />
  }
  return (
    <View style={[styles.containerIcon, { backgroundColor: bgColor, width: bgColor !== 'transparent' ? 86 : 'auto', height: bgColor !== 'transparent' ? 86 : 'auto' }]}>
      <IconCustom name={nameIcon} size={sizeIcon} color={colorIcon} />
    </View>
  )
}

export default Icon
