import { useWindowDimensions } from 'react-native'

const rezisableHook = () => {
  const { height, width } = useWindowDimensions()
  return {
    isPortrait: height > width
  }
}

export default rezisableHook
