import { ErrorMessage } from '@hookform/error-message'
import { MainButton, Typography, ProgressBar, AroundDetails } from 'components'
import React, { useState, useMemo, memo } from 'react'
import { Controller, useForm, ValidationMode } from 'react-hook-form'
import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  View,
  Platform,
  KeyboardAvoidingView
} from 'react-native'
import { Switch } from 'react-native-paper'
import Input from 'components/Input'
import { RouterProps } from 'hooks/Route'
import { useEnv } from 'hooks/index'
import { UserType } from 'hooks/types'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  imageLandscape: {
    height: 250,
    marginVertical: '5%'
  }
})

const LegalRep = ({
  navigation,
  user,
  userActions: { addLegalRep }
}: {
 user: UserType
 userActions: { addLegalRep: (data) => void }
} & RouterProps) => {
  const legalRep = user.clients.find(
    ({ contactType }) => contactType === 'client_legal_coordinator'
  )

  const formConfig: {
  mode: keyof ValidationMode
  defaultValues: {
   name: string
   lastName: string
   email: string
   phone: string
  }
 } = useMemo(() => {
   return {
     mode: 'onChange',
     defaultValues: {
       name: legalRep?.name,
       lastName: legalRep?.lastName,
       email: legalRep?.email,
       phone: legalRep?.phone
     }
   }
 }, [user])
  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm(formConfig)
  const {
    env: { isPortrait, isDevice }
  } = useEnv()

  const [isSwitchOn, setIsSwitchOn] = useState(false)

  const onToggleSwitch = () => setIsSwitchOn(!isSwitchOn)

  const submit = data => {
    if (isSwitchOn) {
      addLegalRep({
        name: user.name,
        lastName: user.lastName,
        email: user.email,
        phone: user.phone
      })
    } else {
      addLegalRep(data)
    }
  }

  return (
  <KeyboardAvoidingView
   behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
   style={{ flex: 1 }}
   keyboardVerticalOffset={90}
  >
   <TouchableWithoutFeedback onPress={() => isDevice && Keyboard.dismiss()}>
    <ScrollView
     style={{ backgroundColor: 'white', opacity: user.loading ? 0.5 : 1 }}
     contentContainerStyle={{ flexGrow: 1 }}
    >
     <View
      style={
       isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape
      }
     >
      {!isPortrait && (
       <View style={styles.leftContainerLandscape}>
        <Typography type="title">Tu around</Typography>
        <AroundDetails user={user} />
       </View>
      )}
      <View
       style={
        isPortrait
          ? styles.rightContainerPortrait
          : styles.rightContainerLandscape
       }
      >
       <ProgressBar progress={0.3} />
       <Typography type="subTitle">Perfil de tu empresa</Typography>
       <Typography type="title">Representante legal</Typography>
       <Typography type="description">
        Esta persona será la que firmará el contrato, te dejaremos saber cuando
        esté listo
       </Typography>
       <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
       >
        <View style={{ flex: 5 }}>
         <Typography type="price">
          ¿Eres el representante legal de tu empresa?
         </Typography>
        </View>
        <View style={{ flex: 1, alignItems: 'flex-end' }}>
         <Switch value={isSwitchOn} onValueChange={onToggleSwitch} />
        </View>
       </View>
       {!isSwitchOn && (
        <>
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            },
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
              message: 'Formato inválido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Correo"
            type="email"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="email"
         />
         <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input label="Nombre" type="text" {...{ value, onChange, onBlur }} />
          )}
          name="name"
         />
         <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Apellidos"
            type="text"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="lastName"
         />
         <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            },
            minLength: {
              value: 8,
              message: 'Formato Inválido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Número"
            type="number"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="phone"
         />
         <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
        </>
       )}
       <View style={{ flex: 1, alignItems: 'flex-end' }}>
        <MainButton
         text="Continuar"
         disabled={!(isSwitchOn || isValid)}
         onClick={handleSubmit(submit)}
        />
       </View>
      </View>
     </View>
    </ScrollView>
   </TouchableWithoutFeedback>
  </KeyboardAvoidingView>
  )
}

export default memo(LegalRep)
