import React, { memo, useCallback } from 'react'
import * as DocumentPicker from 'expo-document-picker'
import MainButton from './MainButton'
import Typography from 'components/Typography'
import { View } from 'react-native'
import * as FileSystem from 'expo-file-system'
import { useEnv } from 'hooks'
// Component POSTs base64 file
interface FileUploadType {
  title?: string
  disabled?: boolean,
  onPickFile: (file:unknown) => void
}

function FileUpload ({ disabled, title, onPickFile }: FileUploadType): JSX.Element {
  const { env: { isDevice } } = useEnv()

  const pickDocument = useCallback(async () => {
    const file = await DocumentPicker.getDocumentAsync()
    if (file.type !== 'success') return onPickFile({ name: 'Cancelado', value: null })

    if (isDevice) {
      const base64 = await FileSystem.readAsStringAsync(file.uri, { encoding: FileSystem.EncodingType.Base64 })
      onPickFile({ name: file.name, value: `data:${file.type};base64,${base64}` })
    } else { // patch for non desktop devices
      onPickFile({ name: file.name, value: file.uri })
    }
  }, [isDevice])

  return (
    <View style={{ marginBottom: 20 }}>
      <Typography type="details">{title}</Typography>
      <MainButton text='Elegir archivo' onClick={pickDocument} disabled={disabled} />
    </View>
  )
}

export default memo(FileUpload)
