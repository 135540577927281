import React from 'react'
import styled from 'styled-components/native'
import { TextProps, ViewProps } from 'react-native'
import { useFonts } from 'expo-font'

interface TypographyProps extends TextProps, Pick<ViewProps, 'pointerEvents'> {
  type: 'title' | 'description' | 'name' | 'details' | 'price' | 'button' | 'terms' | 'termsLink' | 'list' | 'subTitle' | 'error',
  color?: string,
}

const Typography = React.memo(({ type, color, ...rest }: TypographyProps):JSX.Element => {
  const [fontsLoaded] = useFonts({
    'HKGrotesk-Bold': require('../assets/fonts/HKGrotesk-Bold.ttf'),
    'HKGrotesk-Light': require('../assets/fonts/HKGrotesk-Light.ttf'),
    'HKGrotesk-Medium': require('../assets/fonts/HKGrotesk-Medium.ttf')
  })

  const fonts:{ name: string, cases: TypographyProps['type'][] }[] = [
    { name: 'HKGrotesk-Bold', cases: ['title', 'termsLink', 'button', 'list'] },
    { name: 'HKGrotesk-Medium', cases: ['description', 'terms', 'name', 'details', 'price', 'subTitle', 'error'] }
  ]

  const sizes:{ size: string, cases: TypographyProps['type'][] }[] = [
    { size: '45px', cases: ['title'] },
    { size: '20px', cases: ['description'] },
    { size: '28px', cases: ['name', 'price', 'subTitle'] },
    { size: '16px', cases: ['details'] },
    { size: '15px', cases: ['terms', 'termsLink', 'error'] }
  ]
  const colors:{ color: string, cases: TypographyProps['type'][] }[] = [
    { color: 'black', cases: ['title', 'details', 'description', 'name'] },
    { color: '#828282', cases: ['price', 'terms', 'termsLink'] },
    { color: '#F8484E', cases: ['error']}
  ]

  const { name: fontName } = fonts.find(({ cases }) => cases.includes(type)) || { name: 'HKGrotesk-Medium' }
  const { size: sizeNumber } = sizes.find(({ cases }) => cases.includes(type)) || { size: '20px' }
  const { color: colorCode } = colors.find(({ cases }) => cases.includes(type)) || { color: 'black' }

  const StyledText = styled.Text` 
   font-family: ${fontName};
   font-size: ${sizeNumber};
   color: ${color || colorCode};
   text-decoration: ${type === 'termsLink' ? 'underline' : 'none'};
   margin: 6px 0;
  `

  return fontsLoaded && <StyledText {...rest} />
})

export default Typography
