import React, { useEffect, useState } from 'react'
import { useEnv } from 'hooks'
import { StyleSheet, View } from 'react-native'
import ImageComp from './ImageComp'
import Typography from './Typography'
import { UserType } from 'hooks/types'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#A3E0AD',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingTop: 300
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  imagePortrait: {
    height: 150,
    marginVertical: '5%'
  },
  imageLandscape: {
    height: 250,
    marginVertical: '5%'
  }
})

const AroundDetails = ({ user }: {user: UserType}) => {
  const { env: { isPortrait } } = useEnv()
  const [spaceName, setSpaceName] = useState(null)
  const [spacePrice, setSpacePrice] = useState(null)
  const [spaceDetails, setSpaceDetails] = useState(null)
  const [imageURL, setImageURL] = useState(null)
  const [amenitiesList, setAmenitiesList] = useState([])

  const zoneToString = (zone) => {
    const zoneCapital = zone.split('_')
    for (let i = 0; i < zoneCapital.length; i++) {
      zoneCapital[i] = zoneCapital[i].charAt(0).toUpperCase() + zoneCapital[i].slice(1)
    }
    return (zoneCapital.join(' '))
  }

  useEffect(() => {
    const spaceName = user.spaceContract.location_space ? user.spaceContract.location_space.name : null
    setSpaceName(`Around ${spaceName}`)

    const pricePlusIVA = user.rshTransactions[0] ? `$${user.rshTransactions[0].total_plus_iva}` : null
    setSpacePrice(pricePlusIVA)

    let spaceType = ''
    if (user.spaceContract.space_type === 'private') {
      spaceType = 'Privado · '
    } else if (user.spaceContract.space_type === 'shared') {
      spaceType = 'Compartido · '
    } else if (user.spaceContract.space_type === 'floor') {
      spaceType = 'Piso Entero · '
    }
    const squareM = `${user.spaceContract?.m2} m² · `
    const spaceZone = user.spaceContract.location_space ? zoneToString(user.spaceContract.location_space.zone) : null
    setSpaceDetails(`${spaceType}${squareM}${spaceZone}`)

    const spaceImg = user.spaceContract?.rsh_photo_space?.find(({ photo_type: pt }) => pt === 'cover')?.url_photo ||
    'https://tea-prod.s3.amazonaws.com/6429/1-2.jpg'
    setImageURL(spaceImg)

    const checkoutList = user.rshCheckout.length ? user.rshCheckout.map(checkout => (`${checkout.quantity} ${checkout.product_name}`)) : []
    setAmenitiesList([`${user.teamSize} Personas`, ...checkoutList])
    return () => {
    }
  }, [user])

  return (
    <>
      <View style={isPortrait ? styles.imagePortrait : styles.imageLandscape}>
        <ImageComp source={imageURL && imageURL} />
      </View>
      <View style={{ flexWrap: 'wrap', alignItems: 'flex-start', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Typography type="name">{spaceName && spaceName}</Typography>
        <Typography type="price">{spacePrice && spacePrice}</Typography>
      </View>
      <Typography type="details">{spaceDetails && spaceDetails}</Typography>
      {amenitiesList.map((amenitie) => <Typography key={amenitie} type='list'>{amenitie}</Typography>)}
    </>
  )
}

export default AroundDetails
