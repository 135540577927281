import { ErrorMessage } from '@hookform/error-message'
import { AroundDetails, MainButton, ProgressBar, Typography } from 'components'
import { useEnv } from 'hooks'
import React, { memo, useMemo } from 'react'
import { useForm, Controller, ValidationMode } from 'react-hook-form'
import {
  StyleSheet,
  ScrollView,
  View
} from 'react-native'

import Input from 'components/Input'

import { RouterProps } from 'hooks/Route'
import { UserType } from 'hooks/types'

type ProfileData = {
 password: string
 name: string
 lastName?: string
 phone: string
}

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const Profile = ({
  navigation,
  user,
  userActions: { updateInfo }
}: {
 user: UserType
 userActions: { updateInfo: (data: ProfileData) => void }
} & RouterProps) => {
  const formConfig: {
  mode: keyof ValidationMode
  defaultValues: ProfileData
 } = useMemo(() => {
   return {
     mode: 'onChange', // remove to show errors once you press the submit button
     defaultValues: {
       password: '',
       name: user.name,
       lastName: user.lastName,
       phone: user.phone
     }
   }
 }, [user])

  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm<ProfileData>(formConfig)
  const {
    env: { isPortrait }
  } = useEnv()
  return (
    <ScrollView
     style={{ backgroundColor: 'white', opacity: user.loading ? 0.5 : 1, flex: 1 }}
     contentContainerStyle={{ flexGrow: 1 }}
    >
     <View
      style={
       isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape
      }
     >
      {!isPortrait && (
       <View style={styles.leftContainerLandscape}>
        <Typography type="title">Tu around</Typography>
        <AroundDetails user={user} />
       </View>
      )}
      <View
       style={
        isPortrait
          ? styles.rightContainerPortrait
          : styles.rightContainerLandscape
       }
      >
       <ProgressBar progress={0.1} />
       <Typography type="title">Perfil de la empresa</Typography>
       <Typography type="subTitle">
        Para continuar vamos a crear el perfil de tu empresa
       </Typography>
       <Typography type="description">
        Para entrar a tu around necesitamos algunos datos más
       </Typography>

       <Controller
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Requerido'
          },
          minLength: {
            value: 5,
            message: 'Muy débil'
          }
        }}
        render={({ field: { value, onChange, onBlur } }) => (
         <Input
          label="Contraseña"
          type="password"
          {...{ value, onChange, onBlur }}
         />
        )}
        name="password"
       />
       <ErrorMessage
        errors={errors}
        name="password"
        render={({ message }) => (
         <Typography type="error">{message}</Typography>
        )}
       />

       <Controller
        control={control}
        rules={{
          required: {
            value: true,
            message: 'Requerido'
          },
          pattern: {
            value:
           /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+\s*$/i,
            message: 'Formato inválido'
          }
        }}
        render={({ field: { value, onChange, onBlur } }) => (
         <Input label="Nombre" type="name" {...{ value, onChange, onBlur }} />
        )}
        name="name"
       />
       <ErrorMessage
        errors={errors}
        name="name"
        render={({ message }) => (
         <Typography type="error">{message}</Typography>
        )}
       />

       <Controller
        control={control}
        rules={{
          pattern: {
            value:
           /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+\s*$/i,
            message: 'Formato inválido'
          }
        }}
        render={({ field: { value, onChange, onBlur } }) => (
         <Input
          label="Apellidos"
          type="name"
          {...{ value, onChange, onBlur }}
         />
        )}
        name="lastName"
       />
       <ErrorMessage
        errors={errors}
        name="lastName"
        render={({ message }) => (
         <Typography type="error">{message}</Typography>
        )}
       />

       <Controller
        control={control}
        rules={{
          minLength: {
            value: 8,
            message: 'Formato invalido'
          },
          required: {
            value: true,
            message: 'Requerido'
          }
        }}
        render={({ field: { value, onChange, onBlur } }) => (
         <Input
          label="Whatsapp"
          type="number"
          {...{ value, onChange, onBlur }}
         />
        )}
        name="phone"
       />
       <ErrorMessage
        errors={errors}
        name="phone"
        render={({ message }) => (
         <Typography type="error">{message}</Typography>
        )}
       />

       <MainButton
        disabled={!isValid}
        text="Crear cuenta"
        onClick={handleSubmit(updateInfo)}
       />
      </View>
     </View>
    </ScrollView>
  )
}

export default memo(Profile)
