import React from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { Icon, Typography } from 'components'
import rezisableHook from '../hooks/rezisableHook'
import { RouterProps } from 'hooks/Route'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: '#A3E0AD',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: '#A3E0AD',
    flexDirection: 'column'
  },
  secondaryContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  }

})

const Complete = (props:RouterProps) => {
  const screen = rezisableHook()

  return (
    <ScrollView style={{ backgroundColor: 'white' }} contentContainerStyle={{ flexGrow: 1 }}>
      <View style={screen.isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape}>
        <View style={styles.secondaryContainer}>
          <Typography type="title">El perfil de tu empresa ha sido completado</Typography>
          <Icon nameIcon="done" colorIcon='white' sizeIcon={50} bgColor="black" />
        </View>
      </View>
    </ScrollView>
  )
}

export default Complete
