import React from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'
import { AroundDetails, Icon, MainButton, Typography } from 'components'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import { useEnv } from 'hooks'
import { UserType } from 'hooks/types'

type SucessProps = NativeStackScreenProps<{
  Success: {},
  Login: {},
  Profile: {},
  TaxData: {},
  LegalRepresentative: {},
  TempLogin: {},
}, 'Success'>

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#A3E0AD',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingTop: 300
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const Success = ({ navigation, user }: {user: UserType } & SucessProps) => {
  const { env: { isPortrait } } = useEnv()

  return (
    <ScrollView style={{ backgroundColor: 'white' }} contentContainerStyle={{ flexGrow: 1 }}>
      <View style={isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape} >
        {!isPortrait && (
          <View style={styles.leftContainerLandscape}>
            <Typography type="title">Tu pago ha sido correcto</Typography>
            <Icon nameIcon="done" colorIcon='white' sizeIcon={50} bgColor="black" />
          </View>
        )}
        {isPortrait && (
          <View style={styles.leftContainerPortrait}>
            <Typography type="title">Tu pago ha sido correcto</Typography>
            <Icon nameIcon="done" colorIcon='white' sizeIcon={50} bgColor="black" />
          </View>
        )}
        <View style={isPortrait ? styles.rightContainerPortrait : styles.rightContainerLandscape} >
          <Typography type="title">Tu around</Typography>
          <Typography type="description">Has realizado tu pago con éxito, el around es practicamente tuyo, ahora solo debes completar el perfil de tu empresa.</Typography>
          <AroundDetails user={user} />
          <MainButton text="Continuar con el proceso" onClick={() => navigation.navigate('Profile')} />
        </View>
      </View>
    </ScrollView>
  )
}

export default Success
