import React from 'react'
import { Image, StyleSheet, View } from 'react-native'

// This image component will resize to the parents size following responsiveness principles

interface ImageType {
  source: string
}

const styles = StyleSheet.create({
  imageContainer: {
    borderRadius: 30,
    width: '100%',
    height: '100%'
  },
  image: {
    width: '100%',
    height: '100%',
    resizeMode: 'cover',
    borderRadius: 30
  }
})

function ImageComp ({ source }: ImageType): JSX.Element {
  return (
    <View style={styles.imageContainer}>
      <Image style={styles.image}
        source={{ uri: source }} />
    </View>
  )
}

export default ImageComp
