import AsyncStorage from '@react-native-async-storage/async-storage'
import { MainButton, Typography } from 'components'
import { useEnv } from 'hooks'

import React, { memo, useEffect, useState } from 'react'

import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  View,
  Platform,
  KeyboardAvoidingView
} from 'react-native'

import Input from 'components/Input'
import { RouterProps } from 'hooks/Route'
import { UserActionsType } from 'hooks/User'
import { UserType } from 'hooks/types'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const Login = ({
  navigation,
  user,
  userActions: { login, verifyLogin }
}: { user: UserType; userActions: UserActionsType } & RouterProps) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {
    env: { isDevice, isPortrait }
  } = useEnv()
  useEffect(() => {
    if(user.id > 0){
      !user.loading && navigation.navigate(user.currentStep)
      setEmail('')
      setPassword('')
    }else{
      navigation.navigate(user.currentStep)
    }
  }, [user])
  useEffect(() => {
    const hasValidSession = async () => {
      try {
        if(user.currentStep == 'Login'){
          verifyLogin()
        }
      } catch(e) {
        console.log(e)
      }
    }
    hasValidSession()
  }, [])
  return (
  <KeyboardAvoidingView
   behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
   style={{ flex: 1 }}
   keyboardVerticalOffset={90}
  >
   <TouchableWithoutFeedback onPress={() => isDevice && Keyboard.dismiss()}>
    <ScrollView
     style={{ backgroundColor: 'white', opacity: user.loading ? 1 : 1 }}
     contentContainerStyle={{ flexGrow: 1 }}
    >
     {user.error && <Typography type="terms">{user.error}</Typography>}
     <View
      style={
       isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape
      }
     >
      {!isPortrait && (
       <View style={styles.leftContainerLandscape}>
        <Typography type="title">Tu around</Typography>
       </View>
      )}
      {(
      <View
       style={
        isPortrait
          ? styles.rightContainerPortrait
          : styles.rightContainerLandscape
       }
      >
       <Typography type="title">Login</Typography>

       <Input
        label="Email"
        value={email}
        onChangeText={setEmail}
        type="email"
       />
       <Input
        label="Contaseña"
        value={password}
        onChangeText={setPassword}
        type="password"
       />

       <MainButton
        text="Iniciar Sesion"
        onClick={() => login({ user: email, pass: password })}
       />
      </View>
      )}

     </View>
    </ScrollView>
   </TouchableWithoutFeedback>
  </KeyboardAvoidingView>
  )
}

export default memo(Login)
