import { useMemo, useState } from 'react'
import useResizableHook from './rezisableHook'
import * as Device from 'expo-device'

export type EnvType = {
    isPortrait: boolean,
    isDesktop: boolean,
    isDevice: boolean
  }

const Env = () => {
  const { isPortrait } = useResizableHook()
  const [isDesktop, setIsDesktop] = useState(false)
  const [isDevice, setIsDevice] = useState(true)

  useMemo(async () => {
    const DeviceType = await Device.getDeviceTypeAsync()
    const DeviceOsId = Device.osBuildId
    setIsDesktop(DeviceType === Device.DeviceType.DESKTOP)
    setIsDevice(DeviceOsId !== null)
  }, [])

  return {
    env: {
      isPortrait,
      isDesktop,
      isDevice
    }
  }
}

export default Env
