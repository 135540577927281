import { ErrorMessage } from '@hookform/error-message'
import { AroundDetails, MainButton, ProgressBar, Typography } from 'components'
import { useEnv } from 'hooks'
import React, { useState, useEffect, useCallback } from 'react'
import {
  Controller,
  useFieldArray,
  useForm,
  ValidationMode
} from 'react-hook-form'

import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  View,
  Platform,
  KeyboardAvoidingView
} from 'react-native'
import { Switch } from 'react-native-paper'
import DropDown from 'react-native-paper-dropdown'
import Input from 'components/Input'
import { RouterProps } from 'hooks/Route'
import { UserType } from 'hooks/types'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const TaxData = ({
  navigation,
  user,
  userActions: { updateTaxData, updateInvoiceCoordinator }
}: {
  user: UserType
  userActions: {
    updateTaxData: (data: any) => void
    updateInvoiceCoordinator: (data: any) => void
  }
} & RouterProps) => {
  const formConfig: {
    mode: keyof ValidationMode
    defaultValues: {
      taxData: {
        name: string
        value: string | number
        label: string
        options?: { label: string; value: string }[]
      }[]
    }
  } = {
    mode: 'onChange',
    defaultValues: {
      taxData: [
        {
          name: 'personType',
          value: user.rshTaxData?.person_type,
          label: 'Persona Física',
          options: [
            {
              label: 'Persona Física',
              value: 'fisic'
            },
            {
              label: 'Persona Moral',
              value: 'moral'
            }
          ]
        },
        {
          name: 'rfc',
          label: 'RFC',
          value: user.rshTaxData?.rfc || 'sete9511025r4'
        },
        {
          name: 'businessName',
          label: 'Razón Social',
          value: user.rshTaxData?.business_name || 'test'
        }
      ]
    }
  }

  const {
    env: { isDevice, isPortrait }
  } = useEnv()

  const [showDropDown, setShowDropDown] = useState(false)
  const [taxSwitch, setTaxSwitch] = useState(false)
  const [taxAddSwitch, setTaxAddSwitch] = useState(false)

  // TODO:type all of this
  const onSubmit = useCallback(
    ({ taxData }) => {
      const parsedData = taxData.reduce(
        (acc, { name, value }) => ({ ...acc, [name]: value }),
        {}
      )
      const { personType, rfc, businessName, email, name, lastName } =
        parsedData
      if (taxSwitch) {
        updateTaxData({ personType, rfc, businessName })
        taxAddSwitch && updateInvoiceCoordinator({ email, name, lastName })
      } else {
        navigation.navigate('LegalRep')
      }
    },
    [taxSwitch]
  )

  const {
    control,
    handleSubmit,
    formState: { isValid, errors }
  } = useForm(formConfig)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'taxData'
  })

  useEffect(() => {
    if (taxSwitch) {
      append([
        {
          name: 'personType',
          label: 'Persona Física',
          options: [
            {
              label: 'Persona Física',
              value: 'fisic'
            },
            {
              label: 'Persona Moral',
              value: 'moral'
            }
          ],
          value: 'fisic'
        },
        {
          name: 'rfc',
          label: 'RFC',
          value: ''
        },
        {
          name: 'businessName',
          label: 'Razón Social',
          value: ''
        }
      ])
    } else {
      remove()
    }
  }, [taxSwitch])

  useEffect(() => {
    if (taxAddSwitch) {
      append([
        {
          name: 'email',
          label: 'Correo',
          value: ''
        },
        {
          name: 'name',
          label: 'Nombre',
          value: ''
        },
        {
          name: 'lastName',
          label: 'Apellidos',
          value: ''
        }
      ])
    } else {
      remove([3, 4, 5])
    }
  }, [taxAddSwitch])
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      style={{ flex: 1 }}
      keyboardVerticalOffset={90}
    >
      <TouchableWithoutFeedback onPress={() => isDevice && Keyboard.dismiss()}>
        <ScrollView
          style={{ backgroundColor: 'white', opacity: user.loading ? 0.5 : 1 }}
          contentContainerStyle={{ flexGrow: 1 }}
        >
          <View
            style={
              isPortrait
                ? styles.mainContainerPortrait
                : styles.mainContainerLandscape
            }
          >
            {!isPortrait && (
              <View style={styles.leftContainerLandscape}>
                <Typography type="title">Tu around</Typography>
                <AroundDetails user={user} />
              </View>
            )}
            <View
              style={
                isPortrait
                  ? styles.rightContainerPortrait
                  : styles.rightContainerLandscape
              }
            >
              <ProgressBar progress={0.2} />
              <Typography type="subTitle">Perfil de tu empresa</Typography>
              <Typography type="title">Facturación</Typography>
              <Typography type="description">
                Cada mes podrás recibir una factura por tus pagos si así lo
                requieres
              </Typography>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <View style={{ flex: 5 }}>
                  <Typography type="price">
                    ¿Requieres facturas por tus pagos?
                  </Typography>
                </View>
                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                  <Switch
                    value={taxSwitch}
                    onValueChange={() => {
                      setTaxSwitch(!taxSwitch)
                      taxSwitch && setTaxAddSwitch(false)
                    }}
                  />
                </View>
              </View>

              {fields
                .filter(
                  ({ name }) =>
                    name === 'personType' ||
                    name === 'rfc' ||
                    name === 'businessName'
                )
                .map(({ id, label, options, name }, index) => (
                  <Controller
                    key={id}
                    control={control}
                    name={`taxData.${index}.value`}
                    rules={{
                      required: {
                        value: true,
                        message: 'Requerido'
                      },
                      pattern: {
                        value:
                          name === 'rfc'
                            ? /^[a-zA-ZÑ&]{3,4}\d{6}(?:[a-zA-Z\d]{3})?$/
                            : /(.*)/,
                        message: 'Formato inválido'
                      }
                    }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <>
                        {(name === 'personType' && (
                          <DropDown
                            label={label}
                            mode={'outlined'}
                            visible={showDropDown}
                            showDropDown={() => setShowDropDown(true)}
                            onDismiss={() => setShowDropDown(false)}
                            value={value}
                            setValue={onChange}
                            list={options || []}
                          />
                        )) || (
                          <Input
                            label={fields[index].label}
                            type="name"
                            {...{ value, onChange, onBlur }}
                          />
                        )}

                        <ErrorMessage
                          errors={errors}
                          name={`taxData.${index}.value`}
                          render={({ message }) => (
                            <Typography type="error">{message}</Typography>
                          )}
                        />
                      </>
                    )}
                  />
                ))}

              {taxSwitch && (
                <View
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                  }}
                >
                  <View style={{ flex: 5 }}>
                    <Typography type="price">
                      ¿Quieres que le enviémos tu factura a alguien mas?
                    </Typography>
                  </View>
                  <View style={{ flex: 1, alignItems: 'flex-end' }}>
                    <Switch
                      value={taxAddSwitch}
                      onValueChange={() => setTaxAddSwitch(!taxAddSwitch)}
                    />
                  </View>
                </View>
              )}

              {fields
                .filter(
                  ({ name }) =>
                    name === 'name' || name === 'lastName' || name === 'email'
                )
                .map(({ id, label, options, name }, index) => (
                  <Controller
                    key={id}
                    control={control}
                    name={`taxData.${index + 3}.value`}
                    rules={{
                      required: {
                        value: true,
                        message: 'Requerido'
                      },
                      pattern: {
                        value:
                          name === 'email'
                            ? /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i
                            : /^[a-zA-Z\s]*$/,
                        message: 'Formato inválido'
                      }
                    }}
                    render={({ field: { value, onChange, onBlur } }) => (
                      <>
                        {(name === 'personType' && (
                          <DropDown
                            label={label}
                            mode={'outlined'}
                            visible={showDropDown}
                            showDropDown={() => setShowDropDown(true)}
                            onDismiss={() => setShowDropDown(false)}
                            value={value}
                            setValue={onChange}
                            list={options || []}
                          />
                        )) || (
                          <Input
                            label={fields[index + 3].label}
                            type="name"
                            {...{ value, onChange, onBlur }}
                          />
                        )}

                        <ErrorMessage
                          errors={errors}
                          name={`taxData.${index + 3}.value`}
                          render={({ message }) => (
                            <Typography type="error">{message}</Typography>
                          )}
                        />
                      </>
                    )}
                  />
                ))}

              <View style={{ alignSelf: 'flex-end' }}>
                <MainButton
                  disabled={!(!taxSwitch || isValid)}
                  text="Continuar"
                  onClick={handleSubmit(onSubmit)}
                />
              </View>
            </View>
          </View>
        </ScrollView>
      </TouchableWithoutFeedback>
    </KeyboardAvoidingView>
  )
}

export default TaxData
