import AsyncStorage from '@react-native-async-storage/async-storage'
import decodeToken from 'jwt-decode'
import { Reducer } from 'react'
import { useReducerAsync, AsyncActionHandlers } from 'use-reducer-async'
import { Routes } from './Route'
import { ContractType, UserType } from './types'

const API = 'https://api.around.to'

const snake = (key: string) =>
  key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)

export const toSnakeCase = (
  object: { [s: string]: unknown } | ArrayLike<unknown>
) =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)]: value
    }),
    {}
  )

const toCamelCase = (
  object: { [s: string]: unknown } | ArrayLike<string>
): { [x: string]: any } =>
  Object.entries(object).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key
        .toLowerCase()
        .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())]: value
    }),
    {}
  )

export interface LoginFields {
  user: string
  pass: string
}

const initialState: UserType = {
  loading: true,
  agreements: false,
  contactType: 'client',
  email: '',
  id: 0,
  name: '',
  sesionToken: '',
  rshTaxData: {
    id: null,
    person_type: '',
    rfc: '',
    business_name: ''
  },
  rshContactFiles: [],
  clients: [],
  spaceContract: {
    m2: ''
  },
  rshTransactions: {},
  rshCheckout: [
    {
      contract_id: null,
      length: null,
      quantity: null,
      product_name: ''
    }
  ],
  rshLegalContractFile: {},
  contractId: null,
  teamSize: 1,
  currentStep: 'Login'
}

type Action = { type: 'UPDATE'; fields: { [x: string]: any } }

const reducer: Reducer<UserType, Action> = (state, action) => {
  switch (action.type) {
    case 'UPDATE':
      return {
        ...state,
        ...action.fields
      }
    default:
      throw new Error('unknown action type')
  }
}

export type Files = {
  name: string
  value: string
  key: string
}[]

export type MemberData = {
  firstName: string
  lastName: string
  email: string
  phone: string
}

export interface UserActionsType {
  login: (fields: LoginFields) => void
  updateInfo: <UpdateData>(data: UpdateData) => void
  uploadDocs: (files: Files) => void
  removeMember: (id: number) => void
  addMember: (data: MemberData) => void
  verifyLogin: () => void
}

type AsyncAction =
  | ({ type: 'LOGIN' } & LoginFields)
  | { type: 'FETCH'; sesionToken: string; id: number }
  | {
      type: 'UPDATE_INFO'
      sesionToken: string
      id: number
      fields: { [x: string]: any }
    }
  | { type: 'UPLOAD_DOCS'; sesionToken: string; id: number; files: Files }
  | {
      type: 'REMOVE_MEMBER'
      sesionToken: string
      id: number
      memberId: number
      locationId: number
    }
  | {
      type: 'ADD_MEMBER'
      sesionToken: string
      id: number
      data: MemberData
      contractId: number
    }
  | {
      type: 'ADD_LEGAL_REP'
      sesionToken: string
      id: number
      data: MemberData
      contractId: number
    }
  | {
      type: 'UPDATE_LEGAL_REP'
      sesionToken: string
      id: number
      data: MemberData
      contractId: number
      legalRepId: number
    }
  | { type: 'CREATE_TAXDATA'; sesionToken: string; id: number }
  | {
      type: 'UPDATE_TAXDATA'
      sesionToken: string
      id: number
      taxInfo: {
        personType: string
        rfc: string
        businessName: string
      }
      taxId: number
    }
  | {
      type: 'CREATE_INVOICE_COORDINATOR'
      sesionToken: string
      id: number
      data: MemberData
      contractId: number
    }
  | {
      type: 'UPDATE_INVOICE_COORDINATOR'
      sesionToken: string
      id: number
      data: MemberData
      contactId: number
    }
  | { type: 'UPDATE_USER'; data: MemberData }
  | { type: 'LOGOUT' }

const asyncActionHandlers: AsyncActionHandlers<
  Reducer<UserType, Action>,
  AsyncAction
> = {
  UPDATE_LEGAL_REP:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, contractId, data, legalRepId }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const response = await fetch(
        `${API}/api/v3/admin/contact/${legalRepId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({
            contract_id: contractId,
            contact_type: 'client_legal_coordinator',
            ...toSnakeCase(data)
          })
        }
        )
        if (response.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        }
      },
  ADD_LEGAL_REP:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, contractId, data }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const response = await fetch(`${API}/api/v3/admin/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({
            contract_id: contractId,
            contact_type: 'client_legal_coordinator',
            ...toSnakeCase(data)
          })
        })
        if (response.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        }
      },
  ADD_MEMBER:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, contractId, data }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const response = await fetch(`${API}/api/v3/admin/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({
            contract_id: contractId,
            contact_type: 'team_member',
            ...toSnakeCase(data)
          })
        })
        if (response.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        }
      },
  REMOVE_MEMBER:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, memberId, locationId }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const response = await fetch(`${API}/api/v3/admin/contact/${memberId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({
            location_id: locationId
          })
        })
        if (response.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        }
      },
  FETCH:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id }) => {
        try {
          const info = await fetch(`${API}/api/v3/admin/contact/${id}/contract`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sesionToken}`
            }
          })

          if (!info.ok) {
            throw new Error('Login failed at fetch info')
          }
          const data: ContractType = await info.json()
          const response = toCamelCase(data)
          const user = response.clients.find(
            ({ contact_type: contactType }) => contactType === 'client'
          )
          // TODO: destructure as many properties as you need

          const userStore = {
            ...user,
            clients: response.clients.map((el) => toCamelCase(el)),
            rsh_checkout: response.rshCheckout,
            rsh_transactions: response.rshTransactions,
            space_contract: response.spaceContract,
            sesion_token: sesionToken,
            rsh_legal_contract_file: response.rshLegalContractFile,
            contract_id: response.id,
            team_size: response.teamSize
          // TODO :enfonce this 💩
          }
          dispatch({
            type: 'UPDATE',
            fields: { ...toCamelCase(userStore), loading: false }
          })
          if (!userStore.rsh_tax_data) {
            asyncActionHandlers.CREATE_TAXDATA({ dispatch, ...rest })({
              type: 'CREATE_TAXDATA',
              sesionToken,
              id
            })
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE',
            fields: { loading: false, error: e.message }
          })
        }
      },
  LOGIN:
    ({ dispatch, ...rest }) =>
      async ({ user, pass }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        try {
          const login = await fetch(`${API}/api/v3/login`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              email: user,
              password: pass,
              contact_type: 'client'
            })
          })

          if (!login.ok) {
            throw new Error('Login failed')
          }

          const { sesion_token: sesionToken } = await login.json()
          const decoded: { [x: string]: any } = decodeToken(sesionToken)

          const id = decoded.id || ''
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })

          await AsyncStorage.setItem('@tokenSesion', sesionToken)
          await AsyncStorage.setItem('@idUser', id)
        } catch (e) {
          dispatch({
            type: 'UPDATE',
            fields: { loading: false, error: e.message }
          })
        }
      },

  UPDATE_INFO:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, fields }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const res = await fetch(`${API}/api/v3/admin/contact/${id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify(toSnakeCase(fields))
        })
        if (res.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        } else {
          throw new Error('Update failed')
        }
      },
  UPLOAD_DOCS:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, files }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const res = await fetch(`${API}/api/v3/admin/contact/${id}/files`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({
            docs: files.map(({ name, value, key }) => ({
              data: value,
              document_type: snake(key),
              mime_type: 'image/png',
              name_file: name
            }))
          })
        })

        if (res && res.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        } else {
          throw new Error('Update failed')
        }
      },
  CREATE_TAXDATA:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        const res = await fetch(`${API}/api/v3/admin/contact/${id}/tax_data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${sesionToken}`
          },
          body: JSON.stringify({ currency: 'mxn' })
        })

        if (res && res.ok) {
          asyncActionHandlers.FETCH({ dispatch, ...rest })({
            type: 'FETCH',
            sesionToken,
            id
          })
        } else {
          throw new Error('Update failed')
        }
      },
  UPDATE_TAXDATA:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, taxInfo, taxId }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        try {
          const res = await fetch(
          `${API}/api/v3/admin/contact/tax_data/${taxId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sesionToken}`
            },
            body: JSON.stringify(toSnakeCase(taxInfo))
          }
          )

          if (res.ok) {
            asyncActionHandlers.FETCH({ dispatch, ...rest })({
              type: 'FETCH',
              sesionToken,
              id
            })
          } else {
            throw new Error('Update failed')
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE',
            fields: { loading: false, error: e.message }
          })
        }
      },
  CREATE_INVOICE_COORDINATOR:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, contractId, data }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        try {
          const response = await fetch(`${API}/api/v3/admin/contact`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sesionToken}`
            },
            body: JSON.stringify({
              contract_id: contractId,
              contact_type: 'client_invoice_coordinator',
              ...toSnakeCase(data)
            })
          })
          if (response.ok) {
            asyncActionHandlers.FETCH({ dispatch, ...rest })({
              type: 'FETCH',
              sesionToken,
              id
            })
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE',
            fields: { loading: false, error: e.message }
          })
        }
      },
  UPDATE_INVOICE_COORDINATOR:
    ({ dispatch, ...rest }) =>
      async ({ sesionToken, id, data, contactId }) => {
        dispatch({ type: 'UPDATE', fields: { loading: true } })
        try {
          const response = await fetch(
          `${API}/api/v3/admin/contact/${contactId}`,
          {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${sesionToken}`
            },
            body: JSON.stringify({
              contact_type: 'client_invoice_coordinator',
              ...toSnakeCase(data)
            })
          }
          )
          if (response.ok) {
            asyncActionHandlers.FETCH({ dispatch, ...rest })({
              type: 'FETCH',
              sesionToken,
              id
            })
          } else {
            throw new Error('Error creating invoice cordinator')
          }
        } catch (e) {
          dispatch({
            type: 'UPDATE',
            fields: { loading: false, error: e.message }
          })
        }
      },
  UPDATE_USER:
    ({ dispatch }) =>
      async ({ data }) =>
        dispatch({ type: 'UPDATE', fields: data }),
  LOGOUT:
    ({ dispatch }) =>
      async () => {
        await AsyncStorage.removeItem('@tokenSesion')
        await AsyncStorage.removeItem('@idUser')
        dispatch({ type: 'UPDATE', fields: initialState })
      }
}

const User = () => {
  const [user, dispatchUser] = useReducerAsync(
    reducer,
    initialState,
    asyncActionHandlers
  )

  const currentStep: Routes =
    // fix to let user navigate to the success page
    // user.clients.find(({ contactType }) => contactType === 'team_member') && 'Complete' ||
    (['oficial_id', 'proof_of_address', 'constitutive_act']
      .map((key) =>
        user.rshContactFiles.findIndex(({ document_type: dt }) => dt === key)
      )
      .every((index) => index !== -1) &&
      'Members') ||
    (user.clients.find(
      ({ contactType }) => contactType === 'client_legal_coordinator'
    ) &&
      'LegalDocs') ||
    (user.rshTaxData.business_name &&
      user.rshTaxData.rfc &&
      user.rshTaxData.person_type &&
      'LegalRep') ||
    (user.name && user.phone && 'TaxData') ||
    (user.id && 'Success') ||
    'Login'

  return {
    user: { ...user, currentStep },
    actions: {
      login: (credentials: LoginFields) =>
        dispatchUser({ type: 'LOGIN', ...credentials }),
      verifyLogin: async () => {
        const valueToken = await AsyncStorage.getItem('@tokenSesion')
        const valueId = await AsyncStorage.getItem('@idUser')
        if(valueToken && valueId){
          const { exp }: { [x: string]: any } = decodeToken(valueToken)
          const expiredToken = Date.now() >= exp * 1000
          expiredToken
            ? dispatchUser({ type: 'LOGOUT' })
            : dispatchUser({
              type: 'FETCH',
              sesionToken: valueToken,
              id: parseInt(valueId)
            })
        }
      },
      updateInfo: <UpdateData>(data: UpdateData) =>
        dispatchUser({
          type: 'UPDATE_INFO',
          sesionToken: user.sesionToken,
          id: user.id,
          fields: data
        }),
      uploadDocs: (files: Files) =>
        dispatchUser({
          type: 'UPLOAD_DOCS',
          sesionToken: user.sesionToken,
          id: user.id,
          files
        }),
      removeMember: (id: number) =>
        dispatchUser({
          type: 'REMOVE_MEMBER',
          sesionToken: user.sesionToken,
          id: user.id,
          memberId: id,
          locationId: user.spaceContract.location_id
        }),
      addMember: (data: MemberData) => {
        dispatchUser({
          type: 'ADD_MEMBER',
          sesionToken: user.sesionToken,
          id: user.id,
          contractId: user.contractId,
          data
        })
      },
      createTaxData: () =>
        dispatchUser({
          type: 'CREATE_TAXDATA',
          sesionToken: user.sesionToken,
          id: user.id
        }),
      updateTaxData: (taxInfo) =>
        dispatchUser({
          type: 'UPDATE_TAXDATA',
          sesionToken: user.sesionToken,
          id: user.id,
          taxInfo,
          taxId: user.rshTaxData?.id
        }),
      addLegalRep: (data: MemberData) => {
        const legalRep = user.clients.find(
          ({ contactType }) => contactType === 'client_legal_coordinator'
        )
        if (legalRep) {
          dispatchUser({
            type: 'UPDATE_LEGAL_REP',
            sesionToken: user.sesionToken,
            id: user.id,
            contractId: user.contractId,
            data,
            legalRepId: legalRep.id
          })
        } else {
          dispatchUser({
            type: 'ADD_LEGAL_REP',
            sesionToken: user.sesionToken,
            id: user.id,
            contractId: user.contractId,
            data
          })
        }
      },
      updateInvoiceCoordinator: (data: MemberData) => {
        const invoiceCoordinator = user.clients.find(
          (client) => client.contactType === 'client_invoice_coordinator'
        )

        if (invoiceCoordinator) {
          dispatchUser({
            type: 'UPDATE_INVOICE_COORDINATOR',
            sesionToken: user.sesionToken,
            id: user.id,
            contactId: invoiceCoordinator.id,
            data
          })
        } else {
          dispatchUser({
            type: 'CREATE_INVOICE_COORDINATOR',
            sesionToken: user.sesionToken,
            id: user.id,
            contractId: user.contractId,
            data
          })
        }
      },
      updateUser: (data) => dispatchUser({ type: 'UPDATE_USER', data }),
      logut: () => dispatchUser({ type: 'LOGOUT' })
    }
  }
}

export default User
