import React from 'react'
import { View } from 'react-native'

const ProgressBar = ({ progress }: {progress:number}) =>
  (<View style={{ flexDirection: 'row', backgroundColor: 'lightgray', borderRadius: 3, overflow: 'hidden', marginBottom: 10, height: 5, width: 250 }}>
    <View style={{ flex: progress * 100, height: 6, backgroundColor: '#FD8898' }} />
    <View style={{ flex: 100 - progress * 100 }} />
  </View>)

export default ProgressBar
