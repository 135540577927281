import { ErrorMessage } from '@hookform/error-message'
import { AroundDetails, MainButton, ProgressBar, Typography } from 'components'
import { useEnv } from 'hooks'
import React, { memo, useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'

import {
  StyleSheet,
  TouchableWithoutFeedback,
  Keyboard,
  ScrollView,
  View,
  Platform,
  KeyboardAvoidingView
} from 'react-native'

import Input from 'components/Input'
import { RouterProps } from 'hooks/Route'
import { UserActionsType } from 'hooks/User'
import { UserType } from 'hooks/types'

const styles = StyleSheet.create({
  mainContainerLandscape: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'row'
  },
  mainContainerPortrait: {
    flex: 1,
    backgroundColor: 'white',
    flexDirection: 'column'
  },
  leftContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    paddingHorizontal: '5%',
    paddingVertical: '5%'
  },
  rightContainerPortrait: {
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  },
  leftContainerLandscape: {
    flex: 3,
    backgroundColor: '#F2F2F2',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '5%'
  },
  rightContainerLandscape: {
    flex: 4,
    backgroundColor: 'white',
    justifyContent: 'flex-start',
    paddingVertical: '5%',
    paddingHorizontal: '10%'
  }
})

const Members = ({
  navigation,
  user,
  userActions: { removeMember, addMember }
}: { user: UserType; userActions: UserActionsType } & RouterProps) => {
  const {
    env: { isPortrait, isDevice }
  } = useEnv()
  const MAX_MEMBERS = user.teamSize || 1

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid, errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      lastName: '',
      email: '',
      phone: ''
    }
  })

  const existingMembers = useMemo(
    () => user.clients.filter(({ contactType: ct }) => ct === 'team_member'),
    [user]
  )

  useEffect(() => {
    reset()
  }, [user])

  return (
  <KeyboardAvoidingView
   behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
   style={{ flex: 1 }}
   keyboardVerticalOffset={90}
  >
   <TouchableWithoutFeedback onPress={() => isDevice && Keyboard.dismiss()}>
    <ScrollView
     style={{ backgroundColor: 'white', opacity: user.loading ? 0.5 : 1 }}
     contentContainerStyle={{ flexGrow: 1 }}
    >
     <View
      style={
       isPortrait ? styles.mainContainerPortrait : styles.mainContainerLandscape
      }
     >
      {!isPortrait && (
       <View style={styles.leftContainerLandscape}>
        <Typography type="title">Tu around</Typography>
        <AroundDetails user={user} />
       </View>
      )}
      <View
       style={
        isPortrait
          ? styles.rightContainerPortrait
          : styles.rightContainerLandscape
       }
      >
       <ProgressBar progress={0.1} />
       <Typography type="subTitle">Perfil de la empresa</Typography>
       <Typography type="title">Agrega miembros</Typography>
       <Typography type="terms">
        Comparte con tu equipo su nuevo around. Agrega sus datos o copia y
        comparte este link para que completen sus datos:
       </Typography>

       {existingMembers.length < MAX_MEMBERS && (
        <>
         <Typography
          type="price"
          style={{
            justifyContent: 'space-between',
            display: 'flex',
            alignItems: 'center'
          }}
         >
          {`Miembro ${existingMembers.length + 1}`}
         </Typography>
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            },
            pattern: {
              value: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i,
              message: 'Formato inválido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Correo"
            type="email"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="email"
         />
         <ErrorMessage
          errors={errors}
          name="email"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            required: {
              value: true,
              message: 'Requerido'
            },
            pattern: {
              value:
             /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/i,
              message: 'Formato inválido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input label="Nombre" type="name" {...{ value, onChange, onBlur }} />
          )}
          name="name"
         />
         <ErrorMessage
          errors={errors}
          name="name"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            pattern: {
              value:
             /^[a-zA-ZÀ-ÿ\u00f1\u00d1]+(\s*[a-zA-ZÀ-ÿ\u00f1\u00d1]*)*[a-zA-ZÀ-ÿ\u00f1\u00d1]+$/i,
              message: 'Formato inválido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Apellidos"
            type="name"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="lastName"
         />
         <ErrorMessage
          errors={errors}
          name="lastName"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
         <Controller
          control={control}
          rules={{
            minLength: {
              value: 8,
              message: 'Formato invalido'
            },
            required: {
              value: true,
              message: 'Requerido'
            }
          }}
          render={({ field: { value, onChange, onBlur } }) => (
           <Input
            label="Whatsapp"
            type="number"
            {...{ value, onChange, onBlur }}
           />
          )}
          name="phone"
         />
         <ErrorMessage
          errors={errors}
          name="phone"
          render={({ message }) => (
           <Typography type="error">{message}</Typography>
          )}
         />
        </>
       )}
       <View
        style={{
          justifyContent:
          existingMembers.length < MAX_MEMBERS ? 'space-between' : 'center',
          flexDirection: 'row'
        }}
       >
        {existingMembers.length < MAX_MEMBERS && (
         <MainButton
          disabled={!isValid}
          text="Agregar miembro"
          onClick={handleSubmit(addMember)}
         />
        )}
        <MainButton
         disabled={existingMembers.length === 0}
         text="Continuar"
         onClick={() => navigation.navigate('Complete')}
        />
       </View>
       {existingMembers
         .reverse()
         .map(({ name, lastName, email, phone, id }, index) => (
         <View key={index}>
          <Typography
           type="price"
           style={{
             justifyContent: 'space-between',
             display: 'flex',
             alignItems: 'center'
           }}
          >
           {`Miembro ${existingMembers.length - index}`}
           <MainButton text="Eliminar" onClick={() => removeMember(id)} />
          </Typography>

          <Input label="Correo" type="email" value={email} disabled />

          <Input label="Nombre" type="name" value={name} disabled />

          <Input label="Apellidos" type="name" value={lastName} disabled />

          <Input label="Whatsapp" type="number" value={phone} disabled />
         </View>
         ))}
      </View>
     </View>
    </ScrollView>
   </TouchableWithoutFeedback>
  </KeyboardAvoidingView>
  )
}

export default memo(Members)
