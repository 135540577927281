import React, { ComponentProps } from 'react'
import { KeyboardTypeOptions, TextInputAndroidProps } from 'react-native'
import { TextInput } from 'react-native-paper'
import styled from 'styled-components/native'

type TextInputProps = ComponentProps<typeof TextInput>
type InputTypes = {
  type: 'email' | 'password' | 'name' | 'number',
  onChange: (text:string) => void,
} & TextInputProps

const Input = ({ type, onChange, ...rest }:InputTypes) => {
  const extraProps : {
    autoCapitalize?: TextInputProps['autoCapitalize'],
    autoCompleteType?:TextInputAndroidProps['autoCompleteType'],
    keyboardType?: KeyboardTypeOptions,
    secureTextEntry?: boolean,
  } =
   (type === 'email' && {
     keyboardType: 'email-address',
     autoCapitalize: 'none',
     autoCompleteType: 'email'
   }) ||
  (type === 'password' && {
    autoCompleteType: 'password',
    autoCapitalize: 'none',
    secureTextEntry: true
  }) ||
   (type === 'name' && {
     autoCompleteType: 'name',
     autoCapitalize: 'words'
   }) ||
   (type === 'number' && {
     autoCompleteType: 'tel',
     autoCapitalize: 'none',
     keyboardType: 'phone-pad'
   })

  return (
    <TextInput
            mode="outlined"
            onChangeText={onChange}
            {...extraProps}
            {...rest}
        />
  )
}

const StyledInput = styled(Input)`
  margin: 13px 0px;
`
export default StyledInput
